<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-text-field
      class="rounded-0 mb-4"
      background-color="#F7F7F7"
      solo
      flat
      :label="placeholder"
      single-line
      filled
      :hide-details="hideDetails"
      :disabled="disabled"
      :value="value"
      :hint="hint"
      :persistent-hint="persistentHint"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :error-messages="errorMessages"
      :append-icon="appendIcon"
    ></v-text-field>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    value: String,
    errorMessages: [Array, String],
    hideDetails: [Boolean, String],
    disabled: {
      type: Boolean,
      default: false,
    },
    appendIcon: null,
    hint: String,
    persistentHint: Boolean,
  },
  emits: ["update:value"],
};
</script>

<style lang="scss" scoped></style>
