<template>
  <page-modal v-model="show" :title="modalTitle" grayBackground>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-tabs v-model="tab">
        <v-tab v-for="contentTab in tabs" :key="contentTab.id">
          {{ contentTab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="fill-height fill-width px-4">
        <tab-general
          :form="form"
          :organizations="organizations"
          @updateForm="updateForm"
        />
      </v-tabs-items>
    </template>
    <template #actions>
      <v-btn
        v-if="organizationGroup && organizationGroup.id"
        color="blue darken-1"
        text
        :disabled="toggleActiveLoading"
        :loading="toggleActiveLoading"
        @click="toggleActive"
      >
        <v-icon class="black--text">{{
          organizationGroup && organizationGroup.active
            ? "mdi-toggle-switch-outline"
            : "mdi-toggle-switch-off-outline"
        }}</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{
            organizationGroup && organizationGroup.active
              ? "DEACTIVATE"
              : "ACTIVATE"
          }}
        </p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        :loading="loading"
        @click="saveOrganizationGroup"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ organizationGroup && organizationGroup.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "@/Components/PageModal.vue";
import TabGeneral from "./FormTabs/TabGeneral.vue";

export default {
  components: { PageModal, TabGeneral },
  props: {
    organizationGroup: Object,
    organizations: Array,
    value: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        organizationIds: [],
      }),
      tab: null,
      tabs: [
        {
          id: 1,
          name: "GENERAL",
        },
      ],
      active: null,
      loading: false,
      toggleActiveLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.organizationGroup && this.organizationGroup.id)
        return `EDIT ORGANIZATION GROUP: ${this.organizationGroup.name}`;

      return "CREATE ORGANIZATION GROUP";
    },
  },
  methods: {
    setForm(organizationGroup) {
      if (!organizationGroup) {
        this.form.reset();
        this.active = null;
        return;
      }

      this.form.name = organizationGroup.name;
      this.form.organizationIds =
        organizationGroup.organizations?.map((x) => x.id) ?? [];
      this.active = organizationGroup.active;
    },
    saveOrganizationGroup() {
      if (this.loading) return;

      this.loading = true;

      if (this.organizationGroup?.id) {
        this.form
          .transform((data) => serialize(data))
          .put(
            this.route("organization-groups.update", this.organizationGroup.id),
            {
              preserveScroll: true,
              onSuccess: () => {
                this.show = false;
                this.$emit("clear");
              },
              onFinish: () => {
                this.loading = false;
              },
            }
          );
      } else {
        this.form
          .transform((data) => serialize(data))
          .post(this.route("organization-groups.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.setForm(null);
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    toggleActive() {
      if (this.toggleActiveLoading || !this.organizationGroup) return;

      this.toggleActiveLoading = true;

      this.$inertia
        .form({ active: !this.active })
        .transform((data) => serialize(data))
        .patch(
          this.route(
            "organization-groups.active.toggle",
            this.organizationGroup.id
          ),
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              this.toggleActiveLoading = false;

              this.$emit("reload", this.organizationGroup.id);
            },
          }
        );
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
  },
  watch: {
    organizationGroup: function (organizationGroup) {
      this.setForm(organizationGroup);
    },
  },
};
</script>
