<template>
  <v-tab-item class="fill-width min-h-104">
    <div class="fill-height fill-width">
      <v-row>
        <v-col md="4">
          <v-checkbox
            v-if="false"
            solo
            dense
            :input-value="form.moduleFixedAssets"
            :label="'Fixed Assets'"
            :error-messages="form.errors.moduleFixedAssets"
            @change="toggleFixedAssets($event)"
          ></v-checkbox>
          <div v-if="form.moduleFixedAssets">
            <div class="mb-4">
              <v-autocomplete
                v-model="defaultDepreciationMethodIdProxy"
                :items="depreciationMethods"
                item-text="value"
                item-value="id"
                solo
                hide-details
                label="Depreciation method"
                clearable
                :error-messages="form.errors.defaultDepreciationMethodId"
              />
            </div>
            <div class="mb-4">
              <v-autocomplete
                v-model="fiscalYearProxy"
                :items="fiscalYears"
                solo
                hide-details
                label="Fiscal year"
                clearable
                :error-messages="form.errors.fiscalYear"
              />
            </div>
            <div class="mb-4">
              <v-autocomplete
                v-model="defaultStartOfDepreciationIdProxy"
                :items="startOfDepreciations"
                item-text="value"
                item-value="id"
                solo
                hide-details
                label="Start of depreciation"
                clearable
                :error-messages="form.errors.defaultStartOfDepreciationId"
              />
            </div>
          </div>
          <v-checkbox
            solo
            :input-value="form.moduleCo2"
            label="CO2"
            :error-messages="form.errors.moduleCo2"
            @change="updateForm('moduleCo2', $event)"
          ></v-checkbox>
          <v-checkbox
            v-if="false"
            solo
            :input-value="form.moduleAssessments"
            label="Assessments"
            :error-messages="form.errors.moduleAssessments"
            @change="updateForm('moduleAssessments', $event)"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
    depreciationMethods: Array,
    fiscalYears: Array,
    startOfDepreciations: Array,
  },
  emits: ["updateForm"],
  computed: {
    defaultDepreciationMethodIdProxy: {
      get() {
        return this.form.defaultDepreciationMethodId;
      },
      set(value) {
        this.updateForm("defaultDepreciationMethodId", value);
      },
    },
    fiscalYearProxy: {
      get() {
        return this.form.fiscalYear;
      },
      set(value) {
        this.updateForm("fiscalYear", value);
      },
    },
    defaultStartOfDepreciationIdProxy: {
      get() {
        return this.form.defaultStartOfDepreciationId;
      },
      set(value) {
        this.updateForm("defaultStartOfDepreciationId", value);
      },
    },
  },
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
    toggleFixedAssets(value) {
      if (value === false) {
        this.updateForm("defaultDepreciationMethodId", null);
        this.updateForm("defaultStartOfDepreciationId", null);
      }
      this.updateForm("moduleFixedAssets", value);
    },
  },
};
</script>
