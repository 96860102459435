import { integrationSystemLookup, integrationTypeLookup } from "./lookup";

export const roundedNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

export const fullNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 20,
});

export function formatLogEntryInfo(action, parameters) {
  switch (action) {
    case "ResetIntegration":
      return formatResetIntegrationEvent(parameters);
    default:
      return "";
  }
}

function formatResetIntegrationEvent(parameters) {
  const { type, system, systemId } = parameters;

  const formattedType = integrationTypeLookup[type] ?? type ?? "Unknown";
  const formattedSystem =
    integrationSystemLookup[system] ?? system ?? "Unknown";

  const formattedSystemId = systemId ?? "Unknown";

  return `Type: ${formattedType}, System: ${formattedSystem}, System ID: ${formattedSystemId}`;
}
