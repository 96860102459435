<template>
  <v-tab-item :value="tabValue">
    <v-row dense>
      <v-col cols="6" class="px-4">
        <attribute-link-input
          v-model="assetName"
          :attribute="assetNameAttribute"
        />
        <attribute-link-input
          v-model="purchasePrice"
          :second-value="purchasePriceCurrency"
          :attribute="purchasePriceAttribute"
          :currencies="currencies"
          @input_secondValue="purchasePriceCurrency = $event"
        />
        <attribute-link-input
          v-model="purchaseDate"
          :attribute="purchaseDateAttribute"
        />
      </v-col>
      <v-col cols="6" class="px-4">
        <v-row dense>
          <v-col cols="6" class="py-0">
            <attribute-link-input
              v-model="unitType"
              :attribute="unitTypeAttribute"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <attribute-link-input
              :value="quantity"
              :attribute="quantityAttribute"
              :numberMinValue="1"
              @decimal_changed="
                $emit('setDynamicValue', quantityAttribute.id, $event)
              "
            />
          </v-col>
        </v-row>
        <attribute-link-input v-model="model" :attribute="modelAttribute" />
        <asset-rule-fixed-input
          v-model="portfolio"
          :items="portfolios"
          :label="portfolioAttribute.name"
        />
        <asset-rule-fixed-input
          v-if="portfolioSubcategories"
          v-model="portfolioSubcategory"
          :items="portfolioSubcategories"
          :label="portfolioSubcategoryAttribute.name"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>
<script>
import AssetRuleFixedInput from "../AssetRuleFixedInput.vue";
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import {
  assetRuleActionProperty,
  fixedAttributes,
} from "../../../util/fixedAssetData";

export default {
  components: {
    AssetRuleFixedInput,
    AttributeLinkInput,
  },
  props: {
    tabValue: Number,
    attributes: Array,
    currencies: Array,
    portfolios: Array,

    rerenderCounter: Number,

    getDynamicValue: Function,
    getDynamicSecondValue: Function,
    getFixedValue: Function,
  },
  computed: {
    assetNameAttribute() {
      return this.getAttribute(fixedAttributes.generalNameId);
    },
    purchasePriceAttribute() {
      return this.getAttribute(fixedAttributes.generalPurchasePriceId);
    },
    purchaseDateAttribute() {
      return this.getAttribute(fixedAttributes.generalPurchaseDateId);
    },
    quantityAttribute() {
      return this.getAttribute(fixedAttributes.generalQuantityId);
    },
    unitTypeAttribute() {
      return this.getAttribute(fixedAttributes.generalUnitTypeId);
    },
    modelAttribute() {
      return this.getAttribute(fixedAttributes.generalModelId);
    },
    portfolioAttribute() {
      return this.getAttribute(fixedAttributes.generalPortfolioId);
    },
    portfolioSubcategoryAttribute() {
      return this.getAttribute(fixedAttributes.generalPortfolioSubcategoryId);
    },
    assetName: {
      get() {
        return this.getDynamicValue(this.assetNameAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.assetNameAttribute.id, value);
      },
    },
    purchasePrice: {
      get() {
        return this.getDynamicValue(this.purchasePriceAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.purchasePriceAttribute.id, value);
      },
    },
    purchasePriceCurrency: {
      get() {
        return this.getDynamicSecondValue(this.purchasePriceAttribute.id);
      },
      set(value) {
        this.$emit(
          "setDynamicSecondValue",
          this.purchasePriceAttribute.id,
          value
        );
      },
    },
    purchaseDate: {
      get() {
        return this.getDynamicValue(this.purchaseDateAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.purchaseDateAttribute.id, value);
      },
    },
    quantity() {
      this.rerenderCounter;

      return this.getDynamicValue(this.quantityAttribute.id);
    },
    unitType: {
      get() {
        return this.getDynamicValue(this.unitTypeAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.unitTypeAttribute.id, value);
      },
    },
    model: {
      get() {
        return this.getDynamicValue(this.modelAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.modelAttribute.id, value);
      },
    },
    portfolio: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.portfolio);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.portfolio, value);
      },
    },
    portfolioSubcategory: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.portfolioSubcategory);
      },
      set(value) {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.portfolioSubcategory,
          value
        );
      },
    },
    portfolioSubcategories() {
      const portfolio = this.portfolios.find((x) => x.id === this.portfolio);

      return portfolio?.subcategories;
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
  },
};
</script>
