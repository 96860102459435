<template>
  <v-app>
    <v-navigation-drawer
      v-model="navigationDrawer"
      app
      color="#E2DEE3"
      light
      width="200px"
    >
      <v-app-bar flat class="d-flex justify-center" color="#E2DEE3">
        <h1 class="font-weight-light">Prime</h1>
      </v-app-bar>
      <menu-items :url="url()" />
    </v-navigation-drawer>
    <v-main class="base-background-color">
      <v-container fluid fill-height class="align-start main-container">
        <div class="hidden-lg-and-up" style="height: 48px"></div>
        <v-app-bar color="#E2DEE3" dense dark fixed class="hidden-lg-and-up">
          <v-app-bar-nav-icon @click="navigationDrawer = !navigationDrawer">
          </v-app-bar-nav-icon>
        </v-app-bar>
        <slot />
        <v-snackbar
          :value="showError"
          :timeout="2000"
          v-if="errorMessage && !$page.props.flash.hidePopup"
          color="#D32C2C"
          tile
          elevation="3"
        >
          {{ errorMessage }}
        </v-snackbar>
        <v-snackbar
          v-if="successMessage && !$page.props.flash.hidePopup"
          :value="showSuccess"
          :timeout="2000"
          color="#1F7439"
          tile
          elevation="3"
        >
          {{ successMessage }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import MenuItems from "@/Shared/MenuItems";

import { Inertia } from "@inertiajs/inertia";
export default {
  components: {
    MenuItems,
  },
  computed: {
    snackbar() {
      return this.$page.props.flash.success != "";
    },
    successMessage() {
      return this.$page.props.flash.success;
    },
    errorMessage() {
      return this.$page.props.flash.error;
    },
    auth() {
      return this.$page.props.auth;
    },
  },
  data: () => ({
    navigationDrawer: window.innerWidth >= 1264 ? true : false,
    drawer: true,
    mini: true,
    items: [
      {
        title: "Dashboard",
        route: "dashboard.index",
        icon: "mdi-view-dashboard",
      },
      {
        title: "Organizations",
        route: "organizations.index",
        icon: "mdi-file-tree",
      },
    ],
    showSuccess: false,
    showError: false,
  }),
  methods: {
    url() {
      return location.pathname.substr(1);
    },
    enableSuccess() {
      this.showSuccess = true;

      setTimeout(() => (this.showSuccess = false), 2000);
    },
    enableError() {
      this.showError = true;

      setTimeout(() => (this.showError = false), 2000);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    successMessage() {
      this.enableSuccess();
    },
    errorMessage() {
      this.enableError();
    },
  },
  mounted() {
    Inertia.on("success", () => {
      this.drawer = false;
    });

    Inertia.on("finish", () => {
      this.enableSuccess();
      this.enableError();
    });
  },
  updated() {
    document.title = this.title
      ? `${this.title} - LCDPrime Admin Module`
      : "LCDPrime Admin Module";
  },
};
</script>
<style>
.main-container > * {
  width: 100%;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-4\/5 {
  max-width: 80%;
}

.min-h-104 {
  min-height: 26rem;
}

.fill-width {
  width: 100%;
}
.navigation-divider {
  border-color: rgba(255, 255, 255, 0.24) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.text-nowrap {
  text-wrap: nowrap;
}

.gray-background {
  background-color: #e2dee3 !important;
}
</style>
