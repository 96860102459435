<template>
  <page-modal v-model="show" :title="modalTitle">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.name"
                solo
                dense
                type="text"
                :error-messages="form.errors.name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.email"
                solo
                dense
                type="email"
                :error-messages="form.errors.email"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>{{ user && user.id ? "New password" : "Password" }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.password"
                solo
                dense
                type="password"
                :error-messages="form.errors.password"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Organization(s)</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="combinedOrganizationSelection"
                :error-messages="form.errors.organizationIds"
                :items="mergedOrganizationsAndGroups"
                item-text="name"
                item-value="id"
                placeholder="Organization(s)"
                solo
                dense
                multiple
                type="text"
                @change="updateOrganizationIds"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="user && partners?.length" cols="5" offset="2">
          <v-row dense>
            <v-col>
              <span>Partner promotion</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="promotionForm.partnerId"
                :error-messages="promotionForm.errors.partnerId"
                :items="partners"
                item-text="name"
                item-value="id"
                placeholder="Choose partner"
                hide-details="auto"
                solo
                dense
                type="text"
              />
            </v-col>
            <v-col cols="12" class="d-flex mt-2">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="primary"
                :disabled="!promotionForm.partnerId || promoteLoading"
                :loading="promoteLoading"
                @click="promoteToPartner"
              >
                Promote to partner
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <v-btn
        v-if="user && user.id && user.disabledAt"
        color="blue darken-1"
        text
        :loading="deleteUserLoading"
        :disabled="deleteUserLoading"
        @click="deleteUser"
      >
        <v-icon class="black--text">mdi-trash-can-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">DELETE</p>
      </v-btn>
      <v-btn
        v-if="user && user.id"
        color="blue darken-1"
        text
        :loading="toggleActiveLoading"
        :disabled="toggleActiveLoading"
        @click="toggleActive"
      >
        <v-icon class="black--text">{{
          user && user.disabledAt
            ? "mdi-toggle-switch-off-outline"
            : "mdi-toggle-switch-outline"
        }}</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ user && user.disabledAt ? "ACTIVATE" : "DEACTIVATE" }}
        </p>
      </v-btn>
      <v-btn v-if="user && user.id" color="blue darken-1" text>
        <v-icon class="black--text">mdi-email-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">(RE)SEND INVITE</p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading || deleteUserLoading"
        :loading="loading"
        @click="saveUser"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ user && user.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "@/Components/PageModal.vue";

export default {
  components: { PageModal },
  props: {
    organizations: Array,
    organizationGroups: Array,
    partners: Array,
    user: Object,
    value: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        password: null,
        organizationIds: [],
        organizationGroupIds: [],
      }),
      promotionForm: this.$inertia.form({
        partnerId: null,
      }),
      active: false,
      loading: false,
      promoteLoading: false,
      toggleActiveLoading: false,
      deleteUserLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.user && this.user.id) return `EDIT USER: ${this.user.name}`;

      return "CREATE USER";
    },
    mergedOrganizationsAndGroups() {
      const organizationsWithGroupLabel = this.organizationGroups.map(
        (group) => ({
          ...group,
          name: `${group.name} (Organization Group)`,
        })
      );

      return [...this.organizations, ...organizationsWithGroupLabel];
    },
    combinedOrganizationIds() {
      return [...this.form.organizationIds, ...this.form.organizationGroupIds];
    },
    combinedOrganizationSelection: {
      get() {
        return [
          ...this.form.organizationIds,
          ...this.form.organizationGroupIds,
        ];
      },
      set() {},
    },
  },
  methods: {
    setForm(user) {
      if (!user) {
        this.form.reset();
        return;
      }

      this.form.name = user.name;
      this.form.email = user.email;
      this.form.organizationIds = user.organizations?.map((x) => x.id) ?? [];
      this.form.organizationGroupIds =
        user.organizationGroups?.map((x) => x.id) ?? [];
      this.active = !this.user.disabledAt;
    },
    saveUser() {
      if (this.loading) return;

      this.loading = true;

      if (this.user?.id) {
        this.form
          .transform((data) => serialize(data))
          .put(this.route("users.update", this.user.id), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
              this.form.reset();
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      } else {
        this.form
          .transform((data) => serialize(data))
          .post(this.route("users.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    updateOrganizationIds(newVal) {
      newVal.forEach((id) => {
        const isOrganization = this.organizations.some((org) => org.id === id);

        const isOrganizationGroup = this.organizationGroups.some(
          (group) => group.id === id
        );

        if (isOrganization) {
          if (!this.form.organizationIds.includes(id)) {
            this.form.organizationIds.push(id);
          }
          const indexInGroupIds = this.form.organizationGroupIds.indexOf(id);
          if (indexInGroupIds !== -1) {
            this.form.organizationGroupIds.splice(indexInGroupIds, 1);
          }
        } else if (isOrganizationGroup) {
          if (!this.form.organizationGroupIds.includes(id)) {
            this.form.organizationGroupIds.push(id);
          }
          const indexInOrgIds = this.form.organizationIds.indexOf(id);
          if (indexInOrgIds !== -1) {
            this.form.organizationIds.splice(indexInOrgIds, 1);
          }
        }
      });
    },
    toggleActive() {
      if (this.toggleActiveLoading || !this.user) return;

      this.toggleActiveLoading = true;

      this.$inertia
        .form({ active: !this.active })
        .transform((data) => serialize(data))
        .patch(this.route("users.active.toggle", this.user.id), {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.toggleActiveLoading = false;

            this.$emit("reload", this.user.id);
          },
        });
    },
    deleteUser() {
      if (
        !this.user.id ||
        !confirm(
          `Are you sure you want to delete the user named '${this.user.name}'?`
        )
      )
        return;

      this.deleteUserLoading = true;

      this.$inertia.delete(this.route("users.destroy", this.user.id), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          this.show = false;
          this.$emit("clear");
        },
        onFinish: () => {
          this.deleteUserLoading = false;
        },
      });
    },
    promoteToPartner() {
      if (!this.user || !this.promotionForm.partnerId) return;

      const confirmText =
        "Are you sure you want to promote this organization admin? All organizations belonging to this admin will be assigned to the chosen partner.";

      if (!confirm(confirmText)) return;

      this.promoteLoading = true;

      this.promotionForm
        .transform((data) => serialize(data))
        .patch(this.route("users.promote.partner", this.user.id), {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            this.show = false;
            this.$emit("clear");
          },
          onFinish: () => {
            this.promoteLoading = false;
          },
        });
    },
  },
  watch: {
    user: function (user) {
      this.setForm(user);
    },
  },
};
</script>
