<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Organizations</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <organization-form
        v-model="showModal"
        :organization="organization"
        :currencies="currencies"
        :partners="partners"
        :fiscalYears="fiscalYears"
        :startOfDepreciations="startOfDepreciations"
        :depreciationMethods="depreciationMethods"
        :payment-tier-ids="paymentTierIds"
        :organization-trial-data="organizationTrialData"
        :organization-sync-period-data="organizationSyncPeriodData"
        :integration-sync-periods="integrationSyncPeriods"
        @clear="organization = null"
        @reload="reloadOrganization"
      />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-alert v-if="referralLink" type="info" class="referral-link-container">
      <v-row dense align="center">
        <v-col class="grow">Your referral link: {{ referralLink }}</v-col>
        <v-col class="shrink">
          <v-btn @click="copyReferralLink">Copy</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="organizations.data"
      :server-items-length="organizations.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @dblclick:row="(event, { item }) => editOrganization(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.contact="{ item }">
        <span v-if="item.contactName">
          <template v-if="item.contactTitle">
            {{ item.contactTitle }}
          </template>
          <a v-if="item.contactEmail" :href="`mailto:${item.contactEmail}`">
            {{ item.contactName }}
          </a>
          <span v-else>{{ item.contactName }}</span>
        </span>
      </template>
      <template #item.active="{ item }">
        {{ item.active ? "Active" : "Inactive" }}
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <info-popup :item="item" align="left" />
            <action-menu-button
              title="Users"
              icon="mdi-account-group-outline"
            />
            <action-menu-button title="Log" icon="mdi-text-long" />
            <action-menu-button
              title="Edit"
              icon="mdi-pencil"
              @click="editOrganization(item)"
            />
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import InfoPopup from "@/Components/InfoPopup";
import ActionMenuButton from "@/Components/ActionMenuButton";
import OrganizationForm from "./Form.vue";

export default {
  layout: appLayout({ title: "Organizations" }),
  components: {
    InfoPopup,
    ActionMenuButton,
    OrganizationForm,
  },
  props: {
    reloadFilters: Object,
    organizations: Object,
    currencies: Array,
    partners: Array,
    fiscalYears: Array,
    startOfDepreciations: Array,
    depreciationMethods: Array,
    appUrl: String,
    paymentTierIds: Object,
    paymentTrials: Array,
    organizationSyncPeriods: Array,
    integrationSyncPeriods: Array,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Primary contact", value: "contact" },
        { text: "Status", value: "active" },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.organizations?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
      organization: null,
      organizationTrialData: null,
      organizationSyncPeriodData: null,
      showModal: false,
    };
  },
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("organizations.index", query),
        {},
        { preserveState: true }
      );
    },
    editOrganization(organization) {
      this.organization = organization;
      this.setOrganizationTrialData(organization.id);
      this.setOrganizationSyncPeriodData(organization.id);
      this.showModal = true;
    },
    reloadOrganization(organizationId) {
      this.organization =
        this.organizations.data.find((x) => x.id === organizationId) ?? null;

      this.setOrganizationTrialData(organizationId);
      this.setOrganizationSyncPeriodData(organizationId);

      if (!this.organization) this.showModal = false;
    },
    copyReferralLink() {
      if (!this.referralLink) return;

      window.navigator.clipboard.writeText(this.referralLink);
    },
    setOrganizationTrialData(organizationId) {
      this.organizationTrialData =
        this.paymentTrials.find((x) => x.organizationId === organizationId) ??
        null;
    },
    setOrganizationSyncPeriodData(organizationId) {
      this.organizationSyncPeriodData =
        this.organizationSyncPeriods.find(
          (x) => x.organizationId === organizationId
        ) ?? null;
    },
  },
  computed: {
    referralCode() {
      return this.$inertia.page.props.auth.partnerReferralCode;
    },
    referralLink() {
      if (!this.referralCode) return "";

      const url = new URL(this.appUrl);

      url.pathname = `/referrals/${this.referralCode}`;

      return url.toString();
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          ...this.pagination,
          page: 1,
          itemsPerPage: 50,
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
<style scoped>
.referral-link-container:deep(.v-icon) {
  align-self: center;
}
</style>
