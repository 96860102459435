<template>
  <v-tab-item>
    <div class="fill-height fill-width">
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Organization group name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="localForm.name"
                solo
                dense
                type="text"
                :error-messages="localForm.errors.name"
                @input="updateForm('name', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Organization(s)</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="localForm.organizationIds"
                :error-messages="localForm.errors.organizationIds"
                :items="organizations"
                item-text="name"
                item-value="id"
                placeholder="Organization(s)"
                solo
                dense
                multiple
                type="text"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
    organizations: Array,
  },
  emits: ["updateForm"],
  data() {
    return {
      localForm: { ...this.form },
    };
  },
  methods: {
    updateForm(formKey, value) {
      this.localForm[formKey] = value;
      this.$emit("updateForm", formKey, value);
    },
  },
  watch: {
    "localForm.organizationIds": {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.updateForm("organizationIds", newVal);
        }
      },
      deep: true,
    },

    form: {
      handler(newVal) {
        // Update localForm when form changes
        this.localForm = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
