<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Users</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <v-col md="3">
        <v-autocomplete
          v-model="organizationIds"
          :items="organizations"
          placeholder="Organization(s)"
          item-text="name"
          item-value="id"
          multiple
          dense
          hide-details
          solo
        >
          <v-list-item
            slot="prepend-item"
            ripple
            @click="toggleSelectAllOrganizations"
            :class="
              allOrganizationsSelected && 'primary--text v-list-item--active'
            "
          >
            <v-list-item-action>
              <v-simple-checkbox
                :ripple="false"
                :value="allOrganizationsSelected"
              />
            </v-list-item-action>
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>
          <v-divider slot="prepend-item" />
          <template #selection="{ item, index }">
            <span
              v-if="
                organizationIds.length === organizations.length && index === 0
              "
            >
              All Organizations
            </span>
            <span
              v-else-if="
                organizationIds.length !== organizations.length &&
                index < 3 &&
                index !== organizationIds.length - 1
              "
              class="mr-1"
              >{{ item.name }},</span
            >
            <span
              v-else-if="
                organizationIds.length !== organizations.length &&
                index < 3 &&
                index === organizationIds.length - 1
              "
              >{{ item.name }}</span
            >
            <span
              v-else-if="
                organizationIds.length !== organizations.length && index === 3
              "
              >...</span
            >
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="space-between" class="d-flex align-center">
      <user-form
        v-model="showModal"
        :user="user"
        :organizations="organizations"
        :organization-groups="organizationGroups"
        :partners="partners"
        @clear="user = null"
        @reload="reloadUser"
      />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="users.data"
      :server-items-length="users.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      must-sort
      @dblclick:row="(event, { item }) => editUser(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.disabledAt="{ item }">
        {{ item.disabledAt ? "Inactive" : "Active" }}
      </template>
      <template #item.organizations="{ item }">
        <p
          class="organization-list mb-0"
          :title="getCombinedOrganizations(item)"
        >
          {{ getCombinedOrganizations(item) }}
        </p>
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <info-popup :item="item" align="left" />
            <action-menu-button
              title="Login"
              icon="mdi-login"
              :disabled="!!item.disabledAt"
              @click="attemptImpersonation(item)"
            />
            <action-menu-button title="Log" icon="mdi-text-long" />
            <action-menu-button
              title="Edit"
              icon="mdi-pencil"
              @click="editUser(item)"
            />
          </v-row>
        </td>
      </template>
    </v-data-table>
    <organization-dialog
      v-model="showOrganizationModal"
      :organizations="modalOrganizations"
      :user="modalUser"
      @selection="attemptImpersonation"
    />
    <v-dialog
      :value="errorModalMessage !== null"
      :max-width="350"
      hide-overlay
      @click:outside="errorModalMessage = null"
    >
      <v-card>
        <v-card-title>
          <v-icon class="mr-1" color="red">mdi-alert-circle</v-icon>
          Error occurred!
        </v-card-title>
        <v-card-text>
          {{ errorModalMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="errorModalMessage = null">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">CLOSE</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import InfoPopup from "@/Components/InfoPopup";
import ActionMenuButton from "@/Components/ActionMenuButton";
import OrganizationDialog from "@/Components/User/OrganizationDialog";
import UserForm from "./Form.vue";
import qs from "qs";
import { serialize } from "object-to-formdata";

export default {
  layout: appLayout({ title: "Users" }),
  components: { InfoPopup, ActionMenuButton, UserForm, OrganizationDialog },
  props: {
    users: Object,
    reloadFilters: Object,
    organizations: Array,
    organizationGroups: Array,
    partners: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Organization(s)", value: "organizations", sortable: false },
        { text: "Status", value: "disabledAt" },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.users?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "name"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
      organizationIds: searchParams.organizationIds ?? [],
      user: null,
      showModal: false,
      showOrganizationModal: false,
      modalOrganizations: [],
      modalUser: null,
      errorModalMessage: null,
    };
  },
  computed: {
    allOrganizationsSelected() {
      if (!this.organizationIds) return false;

      return this.organizations.every((x) =>
        this.organizationIds.includes(x.id)
      );
    },
  },
  methods: {
    getCombinedOrganizations(item) {
      const organizations = item.organizations.map((org) => org.name);
      const organizationGroups = item.organizationGroups.map(
        (group) => group.name
      );

      const combined = [...organizations, ...organizationGroups];

      return combined.join(", ");
    },
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("users.index", query),
        { organizationIds: this.organizationIds },
        { preserveState: true }
      );
    },
    toggleSelectAllOrganizations() {
      if (this.organizationIds?.length < this.organizations.length)
        this.organizationIds = this.organizations.map((x) => x.id);
      else this.organizationIds = [];
    },
    editUser(user) {
      this.user = user;
      this.showModal = true;
    },
    reloadUser(userId) {
      this.user = this.users.data.find((x) => x.id === userId) ?? null;

      if (!this.user) this.showModal = false;
    },
    attemptImpersonation(user, organizationId) {
      if (!organizationId && user.organizations.length > 1) {
        this.modalOrganizations = user.organizations;
        this.modalUser = user;
        this.showOrganizationModal = true;
        return;
      }

      if (!organizationId) {
        organizationId = user.organizations[0].id;
      }

      const formData = serialize({
        userId: user.id,
        organizationId: organizationId,
      });

      fetch(this.route("api.users.impersonate"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.showOrganizationModal = false;

          if (data.error) {
            this.errorModalMessage = data.error;
            return;
          }

          window.open(data.impersonateLoginUrl, "_blank");
        });
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ["name"],
          sortDesc: [false],
        };
        this.changePage(this.pagination);
      }, 500),
      deep: true,
    },
    organizationIds: debounce(function () {
      this.pagination = {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["name"],
        sortDesc: [false],
      };
      this.changePage(this.pagination);
    }, 250),
  },
};
</script>
<style scoped>
.organization-list {
  white-space: nowrap;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
