<template>
  <div>
    <section-divider v-if="logicOperator" :text="logicOperator" class="mb-4" />
    <v-row no-gutters class="align-start">
      <v-col cols="4">
        <v-subheader>Trigger</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-autocomplete
          :value="propertyName"
          :items="assetRuleTriggerProperties"
          :menu-props="{ top: false, offsetY: true }"
          class="rounded-0 mb-4"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          hide-selected
          solo
          flat
          filled
          light
          @input="$emit('propertyNameChanged', $event)"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters class="align-start">
      <v-col cols="4">
        <v-subheader>Keyword</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field
          :value="value"
          class="rounded-0 mb-4"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          solo
          flat
          filled
          light
          clearable
          @input="$emit('valueChanged', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionDivider from "./SectionDivider.vue";

export default {
  components: { SectionDivider },
  props: {
    propertyName: String,
    value: String,
    logicOperator: String,
    assetRuleTriggerProperties: Array,
  },
  emits: ["propertyNameChanged", "valueChanged"],
};
</script>
