<template>
  <div class="d-flex flex justify-center align-center white">
    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <div
        class="d-flex justify-end mb-4"
        style="position: absolute; top: 30px; right: 50px"
      >
        <a
          href="https://verarca.com/kontakt/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >Need Help</a
        >
      </div>
      <h1 class="text-h4 black--text mb-2 text-center">Welcome to Verarca Admin Module</h1>
      <h1 class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        Please enter your details to log in
      </h1>
      <flash-messages />
      <input
        autofocus
        type="email"
        autocomplete="email"
        v-model="form.email"
        placeholder="Email"
        name="email"
        class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
        style="width: 100%"
      />

      <input
        autofocus
        type="password"
        autocomplete="password"
        v-model="form.password"
        placeholder="Password"
        name="password"
        class="lightGrey black--text pl-5 pt-2 pb-2 mb-2 text-caption input"
        style="width: 100%"
      />

      <div class="d-flex align-center mb-6">
        <v-checkbox
          hide-details
          color="#F25D3B"
          class="pa-0 ma-0 textGrey--text"
          label="Remember me"
          v-model="form.rememberMe"
        ></v-checkbox>
        <v-spacer />
        <inertia-link
          class="mr-4 text-body-2 black--text"
          :href="route('password.request')"
        >
          Forgot password?
        </inertia-link>
      </div>

      <v-btn
        tile
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-2 white--text"
        type="submit"
        :disabled="form.processing"
      >
        Login
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  metaInfo: { title: "Login" },
  layout: Layout,
  components: {
    FlashMessages,
  },
  props: {
    errors: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        email: null,
        password: null,
        rememberMe: true,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route("login.attempt"));
    },
  },
};
</script>
<style scoped>
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
</style>
